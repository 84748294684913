import React from 'react';
import { graphql } from 'gatsby';

export default function ProjectPage({ data, pageContext }) {
  return <></>;
}

// querry all vignett for each rea

export const query = graphql`
  query($slug: String) {
    navInfo: allSanityInfos {
      nodes {
        categories
      }
    }

    navLabo: allSanityLaboratory {
      nodes {
        categories
      }
    }

    navStudio: allSanityStudio {
      nodes {
        categories
      }
    }
    projectInfo: sanityInfos(slug: { current: { eq: $slug } }) {
      categories
      id
      _rawText
      slug {
        current
      }
      images {
        asset {
          fluid(maxWidth: 500) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }

    projectStudio: sanityStudio(slug: { current: { eq: $slug } }) {
      id
      images {
        asset {
          fluid(maxWidth: 500) {
            ...GatsbySanityImageFluid
          }
        }
      }
      slug {
        current
      }
      categories
      project
      date
      _rawText
    }
    projectLabo: sanityLaboratory(slug: { current: { eq: $slug } }) {
      id
      images {
        asset {
          fluid(maxWidth: 500) {
            ...GatsbySanityImageFluid
          }
        }
      }
      slug {
        current
      }
      categories
      project
      date
      _rawText
    }
  }
`;
